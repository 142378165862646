import { Action } from '../actions';

import { StoreState } from '../interfaces';

import {
  SEARCH_LANGUAGES_STORAGE_KEY,
  SUPPORTED_SEARCH_LANGUAGES,
} from '@pressbox-video/ui-constants/filter.constants';
import { IAuth0User, IOrganization } from '@pressbox-video/ui-interfaces/user.interface';
import * as constants from '../constants';

const searchLanguages = localStorage.getItem(SEARCH_LANGUAGES_STORAGE_KEY);

const storeState: StoreState = {
  loading: false,
  auth: {
    email: '',
    username: '',
    credits: 0,
    user: undefined,
  },
  activeTab: 'login',
  keywords: [],
  editProjects: [],
  popup: {
    showPopup: false,
    popupFor: '',
    popupResponse: false,
    popupContent: {
      title: 'purchase video',
      body: 'Are you sure you would like to purchase this video for 100 credits? After your purchase this video will be available to edit and download. ',
    },
  },
  searchLanguages: searchLanguages
    ? JSON.parse(searchLanguages)
    : ['en', 'de', 'fr', 'ar', 'it', 'es'],
};

export function authReducer(state: StoreState = storeState, action: Action): StoreState {
  switch (action.type) {
    case constants.SET_EMAIL:
      return { ...state, auth: { ...state.auth, email: action.email } };
    case constants.SET_USERNAME:
      return { ...state, auth: { ...state.auth, username: action.username } };
    case constants.SWITCHTABS:
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    case constants.SETUSER:
      return {
        ...state,
        auth: {
          ...state.auth,
          username: action.payload.user.user?.name,
          credits: +(action.payload.user.organization?.credits || 0),
          user: {
            user: {
              ...state.auth.user?.user,
              ...action.payload.user.user,
            } as IAuth0User,
            organization: {
              ...state.auth.user?.organization,
              ...action.payload.user.organization,
            } as IOrganization,
          },
        },
      };
    case constants.SETKEYWORDS:
      return {
        ...state,
        keywords: action.payload.keywords,
      };
    case constants.TOGGLEPOPUP:
      return {
        ...state,
        popup: action.payload,
      };
    case constants.SET_SEARCH_LANGUAGES: {
      const searchLanguages = action.payload.searchLanguages.filter((language) =>
        SUPPORTED_SEARCH_LANGUAGES.includes(language)
      );

      localStorage.setItem(SEARCH_LANGUAGES_STORAGE_KEY, JSON.stringify(searchLanguages));

      return {
        ...state,
        searchLanguages,
      };
    }
    case constants.SET_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }
    case constants.SET_PENDO: {
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            user: {
              ...(state?.auth?.user?.user as IAuth0User),
              user_metadata: {
                ...state?.auth?.user?.user?.user_metadata,
                pendo: action.payload.pendo,
              },
            },
          },
        },
      };
    }
  }

  return state;
}
