import Axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react/src/auth0-context';
import { environment } from '../../environments/environment';

export class APIService {
  private static auth0: Auth0ContextInterface;

  public static setAuth0(auth0: Auth0ContextInterface): void {
    this.auth0 = auth0;
  }

  private static async getIdToken(): Promise<string | undefined> {
    try {
      const claims = await this.auth0.getIdTokenClaims();

      return claims?.__raw;
    } catch (err) {
      return undefined;
    }
  }

  private static async getAccessToken() {
    return `Bearer ${await this.auth0.getAccessTokenSilently()}`;
  }

  private static async getHeaders(): Promise<{
    Authorization: string;
    IDToken: string;
    'Content-Type': string;
  }> {
    const idToken = await this.getIdToken();
    const accessToken = await this.getAccessToken();

    if (!idToken) {
      throw new Error('Could not obtain IDToken from Auth0.');
    }

    return { Authorization: accessToken, IDToken: idToken, 'Content-Type': 'application/json' };
  }

  private static async makeRequest<T>(
    path: string,
    options: AxiosRequestConfig = {},
    tryAgain = true
  ): Promise<T> {
    try {
      options.headers = await this.getHeaders();
      options.url = `${environment.API_URL}${path}`;
      const response = await Axios.request(options);

      if (response.data === null) {
        return null as unknown as T;
      }

      if (!response.data.body && response.data.error) {
        throw new Error(response.data.message || 'Something went wrong.');
      }

      return response.data.body ? JSON.parse(response.data.body) : response.data;
    } catch (err) {
      console.error(err);

      if (tryAgain && (err as { response?: { status: number } }).response?.status === 401) {
        return this.makeRequest(path, options, false);
      }

      throw err;
    }
  }

  static async get<T>(path: string, cancelToken?: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      cancelToken: cancelToken,
    };

    return this.makeRequest<T>(path, config);
  }

  static async post<T>(path: string, body: unknown, cancelToken?: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      data: body,
      cancelToken: cancelToken,
    };

    return this.makeRequest<T>(path, config);
  }

  static async put<T>(path: string, body: unknown, cancelToken?: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      data: body,
      cancelToken: cancelToken,
    };

    return this.makeRequest<T>(path, config);
  }

  static async patch<T>(path: string, body: unknown, cancelToken?: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: 'PATCH',
      data: body,
      cancelToken: cancelToken,
    };

    return this.makeRequest<T>(path, config);
  }
  static async delete<T>(path: string, body: unknown, cancelToken?: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      data: body,
      cancelToken: cancelToken,
    };

    return this.makeRequest<T>(path, config);
  }
}
