import React, { createContext, useState } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

import ExistenceOfChangesModal from '.';

export const ExistenceOfChangesContext = createContext<(hasChanged: boolean) => void>(
  () => {} // eslint-disable-line
);

type Props = {
  children?: React.ReactNode;
};

const ExistenceOfChangesProvider = ({ children }: Props) => {
  const [hasChanged, setHasChanged] = useState(false);

  return (
    <ExistenceOfChangesContext.Provider value={setHasChanged}>
      <NavigationPrompt when={hasChanged}>
        {({ onConfirm, onCancel }) => (
          <ExistenceOfChangesModal
            isOpen={true}
            onCancel={onCancel}
            onConfirm={() => {
              setHasChanged(false);
              onConfirm();
            }}
          />
        )}
      </NavigationPrompt>
      {children}
    </ExistenceOfChangesContext.Provider>
  );
};

export default ExistenceOfChangesProvider;
