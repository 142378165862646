import { PAGES } from './admin.constants';

export const ROLES_URL = 'https://admin.video.pressbox.statsperform.com/roles';

export const ROLES = {
  SUPERUSER: 'PBV-Admin:Superuser',
  NEWS: 'PBV-Admin:News',
  GLOBAL_ACTIVATION: 'PBV-Admin:GlobalActivation',
  READ_ONLY: 'PBV-Admin:ReadOnly'
};

export const SECTIONS = [
  PAGES.PRICING_ENGINE,
  PAGES.CREDITS_OVERRIDE,
  PAGES.CONTENT_OVERRIDE,
  PAGES.CREDIT_PACKAGES,
  PAGES.CONTENT_OWNERSHIP_OVERRIDE,
  PAGES.CREDIT_BALANCE_OVERRIDE,
  PAGES.SIGNUP_URL,
  PAGES.VETTING
];

export const SECTIONS_WITH_LOCKS = [
  PAGES.PRICING_ENGINE,
  PAGES.CREDITS_OVERRIDE,
  PAGES.CONTENT_OVERRIDE,
  PAGES.CREDIT_PACKAGES
];

export const NEWS_ROLE_ACCESS = [
  PAGES.CONTENT_OVERRIDE,
  PAGES.CONTENT_OWNERSHIP_OVERRIDE
];

export const GLOBAL_ACTIVATION_ROLE_ACCESS = [
  PAGES.CREDITS_OVERRIDE,
  PAGES.CREDIT_BALANCE_OVERRIDE,
  PAGES.CONTENT_OWNERSHIP_OVERRIDE,
  PAGES.SIGNUP_URL,
  PAGES.VETTING
];
