import React, { createContext, useState } from 'react';
import { PAGES } from '../../constants/admin.constants';
import useSections from './hooks/useSections';

export type Locks = { [key in PAGES]?: boolean };
export type ToggleLock = (page: PAGES) => void;

export const SectionLockContext = createContext<{
  locks: Locks;
  toggleLock: ToggleLock;
}>({} as any);

type Props = {
  children?: React.ReactNode;
};

const SectionLockProvider = ({ children }: Props) => {
  const [locks, setLocks] = useState<{ [key in PAGES]?: boolean }>(
    useSections().reduce((acc, section) => ({ ...acc, [section]: true }), {})
  );

  const toggleLock = (page: PAGES) => {
    setLocks((locks) => ({ ...locks, [page]: !locks[page] }));
  };

  return (
    <SectionLockContext.Provider value={{ locks, toggleLock }}>
      {children}
    </SectionLockContext.Provider>
  );
};

export default SectionLockProvider;
