import { useAuth0 } from '@auth0/auth0-react';
import { PAGES } from '../../../constants/admin.constants';
import { ROLES_URL, ROLES, SECTIONS_WITH_LOCKS, SECTIONS } from '../../../constants/auth0.roles';

const useSections = (): PAGES[] => {
  const { user } = useAuth0();
  const roles = user?.[ROLES_URL] || [ROLES.READ_ONLY];

  if (roles.includes(ROLES.SUPERUSER) || roles.includes(ROLES.GLOBAL_ACTIVATION)) {
    return SECTIONS_WITH_LOCKS;
  }

  if (roles.includes(ROLES.NEWS)) {
    return SECTIONS.filter((section) => section !== PAGES.CONTENT_OWNERSHIP_OVERRIDE);
  }

  return SECTIONS;
};

export default useSections;
